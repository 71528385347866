import { useCallback, useEffect, useState } from 'react'
import { useProfile } from '../ProfileContext'
import { BRAND } from '@shopify/const'
import { CreditLogResponse } from '../response'
import { useAlpcMutation } from './useAlpcFetch'

function useActivities({
  page,
  pageSize,
  options,
}: {
  page: number
  pageSize: number
  options?: any
}) {
  const { isLoading: isProfileLoading, profile } = useProfile()
  const [activities, setActivities] = useState<CreditLogResponse[]>([])
  const [total, setTotal] = useState(0)

  const [currentPage, setCurrentPage] = useState(0)

  const { isMutating: isLoading, trigger } = useAlpcMutation<
    {
      data: {
        total: number
        creditLogs: CreditLogResponse[]
      }
    },
    {
      page: number
      page_size: number
      user_id: string
    }
  >({
    url: `/v1/credit/log`,
    initData: {
      brand: BRAND,
    },
  })

  const getActivities = useCallback(async () => {
    trigger(
      {
        page: page,
        page_size: pageSize,
        user_id: profile?.user_id,
        ...(options || {}),
      },
      {
        onSuccess(responseData) {
          setCurrentPage(page)
          setTotal(responseData?.data?.total)
          setActivities(activities.concat(responseData?.data?.creditLogs || []))
        },
      }
    )
  }, [activities, profile, page, pageSize, options, trigger])

  useEffect(() => {
    if (isProfileLoading || !profile || isLoading) {
      return
    }

    if (page !== currentPage && page) {
      getActivities()
    }
  }, [getActivities, isProfileLoading, isLoading, profile, page, currentPage])

  return {
    activities,
    isLoading,
    total,
    canNext: total > activities.length && !isLoading,
  }
}

export default useActivities
