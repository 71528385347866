import s from '../../Home.module.css'
import cn from 'classnames'
import { Picture } from '@components/ui'
import { useProfile } from 'templates/Pages/Credit/ProfileContext'
import ActivitiesModal from 'templates/Pages/Credit/Modal/ActivitiesModal'
import MyRewardsModal from 'templates/Pages/Credit/Modal/MyRewardsModal'
import { numberFormat, DEnumberFormat } from 'templates/Pages/Credit/utils'
import { useCallback, useState, useMemo } from 'react'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { useUI } from '@components/ui/context'
import { refLink } from '../../utils'
import { handleLocaleLink } from '@lib/utils/tools'
import useFromEu from '@lib/hooks/useFromEu'
import BestPrice from '../BestPrice'

/**
 * Renders the Member component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.section - The section data.
 * @param {string} props.locale - The locale.
 * @param {Object} props.shopCommon - The shop common data.
 * @param {Array} props.relatedProducts - The related products data.
 * @returns {JSX.Element} The rendered Member component.
 */
const Member = ({ section, locale, shopCommon, relatedProducts }) => {
  const [openActivities, setOpenActivities] = useState(false)
  const [openRewards, setOpenRewards] = useState(false)
  const { profile, creditInfo } = useProfile()

  const { setRegistrationsPopup, setRegistrationsModelStatus } = useUI()

  const displayName = useMemo(() => {
    if (!profile) return ''
    const { first_name, last_name, email } = profile
    return first_name && last_name
      ? `${first_name} ${last_name}`
      : email.split('@')[0]
  }, [profile])

  const handleButtonClick = useCallback(
    (buttonConfig) => {
      if (profile) {
        const eventParams = {
          event: 'ga4Event',
          event_name: 'lp_button',
          event_parameters: {
            page_group: 'EufyCredits Rewards Program',
            position: 'info_card',
            button_name: buttonConfig.label,
          },
        }

        pageGTMEvent({
          event: 'uaEvent',
          eventCategory: 'EufyCredits Rewards Program',
          eventAction: buttonConfig.label,
          eventLabel: 'info_card',
        })
        pageGTMEvent(eventParams)
      }

      if (buttonConfig.type) {
        switch (buttonConfig.type) {
          case 'activities':
            setOpenActivities(true)
            break
          case 'rewards':
            setOpenRewards(true)
            break
          case 'link':
            const link = refLink(
              handleLocaleLink({
                locale,
                href: buttonConfig.link,
              }),
              profile ? 'member_login' : 'member_unlogin'
            )
            if (buttonConfig.openNew) {
              window.open(link, '_blank')
            } else {
              location.href = link
            }
            break
          case 'signin':
            setRegistrationsPopup(true)
            setRegistrationsModelStatus('quickLogin')
            break
          case 'signup':
            setRegistrationsPopup(true)
            setRegistrationsModelStatus('main')
            break
          default:
            break
        }
      }
    },
    [locale, profile, setRegistrationsModelStatus, setRegistrationsPopup]
  )

  //fromEu判断是否欧盟国家
  const fromEu = useFromEu({ locale })
  return (
    <>
      <div className="layer relative overflow-hidden">
        <div className="content">
          <div className="relative py-5 min-l:py-10 min-xl:py-[60px]">
            <h2
              className={cn(s.title)}
              dangerouslySetInnerHTML={{ __html: section.title }}
            ></h2>
            <div className="mt-6 min-l:mt-8 min-l:flex min-l:gap-2 min-xl:mt-12">
              <div
                className={cn(
                  'relative min-l:h-[424px] min-l:w-[382px] min-xl:h-[484px] min-xl:w-[532px] min-xl:shrink-0 min-xxl:w-[666px]',
                  {
                    'md:pb-[106px]': profile,
                  }
                )}
              >
                <Picture
                  source={`${section.image}, ${section.image_mob} 1023`}
                  className="h-full w-full rounded-xl"
                  imgClassName="!h-full object-cover"
                />
                <div
                  className={cn(
                    'absolute bottom-0 left-0 w-full p-6 min-l:p-4 min-xl:p-6',
                    {
                      'md:pr-0': !profile,
                    }
                  )}
                >
                  {profile ? (
                    <>
                      <h2 className="break-words font-semibold min-l:text-[20px] min-xl:text-2xl">
                        {section.login?.title?.replace('$user', displayName)}
                      </h2>
                      <p className="mt-2 text-sm font-medium tracking-[-0.28px]">
                        {section.login?.subtitle}
                      </p>
                      <div className="mt-4 rounded-xl bg-white p-3 min-md:flex min-md:items-center min-md:gap-20 min-l:gap-4 min-xl:justify-between">
                        <div className="flex justify-between tracking-[-0.28px] min-md:gap-10 min-l:gap-4 min-xl:gap-8">
                          <div className="max-w-[150px] shrink-0 min-l:max-w-[120px] min-xl:max-w-[150px] min-xxl:max-w-[200px]">
                            <label className="block font-medium min-l:text-xs min-xl:text-base">
                              {section.login?.balance}
                            </label>
                            <div className="mt-1 flex flex-wrap items-end">
                              <em className="text-2xl font-semibold not-italic min-xl:text-[32px]">
                                {fromEu
                                  ? creditInfo
                                    ? DEnumberFormat(
                                        creditInfo.available_credit
                                      )
                                    : 0
                                  : creditInfo
                                  ? numberFormat(creditInfo.available_credit)
                                  : 0}
                              </em>
                              <span className="relative top-[-2px] text-sm font-medium">
                                {section.login?.eufycredits}
                              </span>
                            </div>
                          </div>
                          <div className="max-w-[150px] shrink-0 min-l:max-w-[80px] min-xl:max-w-[120px] min-xxl:max-w-[200px]">
                            <label className="block font-medium min-l:text-xs min-xl:text-base">
                              {section.login?.soon}
                            </label>
                            <div className="mt-1 flex  flex-wrap items-end text-[#9d9d9d]">
                              <em className="text-2xl font-semibold not-italic min-xl:text-[32px]">
                                {fromEu
                                  ? creditInfo
                                    ? DEnumberFormat(creditInfo.pending_credit)
                                    : 0
                                  : creditInfo
                                  ? numberFormat(creditInfo.pending_credit)
                                  : 0}
                              </em>
                              <span className="relative top-[-2px] text-sm font-medium">
                                {section.login?.eufycredits}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4 flex gap-3">
                          {section.login?.btns?.map((btn, index) => (
                            <button
                              key={index}
                              className={cn('w-full text-center', {
                                [s.btnPrimary]: index === 0,
                                [s.btnBasic]: index !== 0,
                                '!w-auto !min-w-[136px] min-l:!min-w-[112px] min-l:!px-3 min-xl:!min-w-[151px]':
                                  section.login?.btns.length === 1,
                              })}
                              onClick={() => handleButtonClick(btn)}
                            >
                              {btn.label}
                            </button>
                          ))}
                        </div>
                      </div>
                      {/* <h2 className="break-words text-3xl font-bold !leading-[1.2] min-l:text-[42px] min-xl:text-[56px]">
                        {section.login?.title?.replace('$user', displayName)}
                      </h2>
                      <p className="mt-4 font-medium">
                        {section.login?.subtitle}
                      </p>
                      <div className="mt-9 rounded-xl bg-white px-6 py-10 shadow-[0_4px_20px_rgba(169,162,156,0.2)] min-md:mx-auto min-md:w-[60vw] min-l:ml-0 min-l:w-[713px] min-l:px-[70px] min-l:py-[44px]">
                        <div className="border-b border-[#e8e8e8] pb-6 min-l:flex min-l:justify-between min-l:gap-12  min-xl:gap-[100px]">
                          <div>
                            <label className="block font-semibold min-l:text-lg">
                              {section.login?.balance}
                            </label>
                            <div className="mt-[10px] min-l:mt-4">
                              <em className="text-4xl font-bold not-italic min-l:text-[44px] min-xl:text-[56px]">
                                {fromEu
                                  ? creditInfo
                                    ? DEnumberFormat(
                                        creditInfo.available_credit
                                      )
                                    : 0
                                  : creditInfo
                                  ? numberFormat(creditInfo.available_credit)
                                  : 0}
                              </em>
                              <span className="ml-2 font-semibold min-l:text-lg">
                                {section.login?.eufycredits}
                              </span>
                            </div>
                          </div>
                          <div>
                            <label className="mt-6 block font-semibold min-l:mt-0 min-l:text-lg">
                              {section.login?.soon}
                            </label>
                            <div className="mt-[10px] text-[#bbb] min-l:mt-4">
                              <em className="text-4xl font-bold not-italic min-l:text-[44px] min-xl:text-[56px]">
                                {fromEu
                                  ? creditInfo
                                    ? DEnumberFormat(creditInfo.pending_credit)
                                    : 0
                                  : creditInfo
                                  ? numberFormat(creditInfo.pending_credit)
                                  : 0}
                              </em>
                              <span className="ml-2 font-semibold min-l:text-lg">
                                {section.login?.eufycredits}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={cn(
                            'mt-7 flex items-center justify-center gap-3 min-l:mt-6 ',
                            {
                              '!justify-start':
                                section.login?.btns?.length === 1,
                            }
                          )}
                        >
                          {section.login?.btns?.map((btn, index) => (
                            <button
                              key={index}
                              className={cn('w-full text-center', {
                                [s.btnPrimary]: index === 0,
                                [s.btnBasic]: index !== 0,
                                '!w-auto !min-w-[136px]':
                                  section.login?.btns.length === 1,
                              })}
                              onClick={() => handleButtonClick(btn)}
                            >
                              {btn.label}
                            </button>
                          ))}
                        </div>
                      </div> */}
                    </>
                  ) : (
                    <>
                      <h2
                        className="break-words font-semibold min-l:text-[20px] min-xl:text-2xl"
                        dangerouslySetInnerHTML={{
                          __html: section.logout?.title,
                        }}
                      ></h2>
                      <p
                        className="mt-2 text-sm font-medium leading-[1.2] tracking-[-0.28px] opacity-75"
                        dangerouslySetInnerHTML={{
                          __html: section.logout?.subtitle,
                        }}
                      ></p>
                      <div className="mt-4 flex items-center gap-2">
                        {section.logout?.btns?.map((btn, index) => (
                          <button
                            key={index}
                            className={cn(
                              'block !whitespace-normal !py-[10px] text-center',
                              {
                                [s.btnBasic]: index === 0,
                                [s.btnPrimary]: index !== 0,
                              }
                            )}
                            onClick={() => handleButtonClick(btn)}
                          >
                            {btn.label}
                          </button>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <BestPrice section={section} relatedProducts={relatedProducts} />
            </div>
          </div>
        </div>
      </div>
      {section.login?.activities && (
        <ActivitiesModal
          isOpen={openActivities}
          data={section.login?.activities}
          common={section.login?.common}
          onClose={() => {
            setOpenActivities(false)
          }}
        />
      )}
      {section.login?.rewards && (
        <MyRewardsModal
          isOpen={openRewards}
          data={section.login?.rewards}
          common={section.login?.common}
          shopCommon={shopCommon}
          onClose={() => {
            setOpenRewards(false)
          }}
        ></MyRewardsModal>
      )}
    </>
  )
}

export default Member
