import { useCallback, useMemo, useState } from 'react'
import { ModalProps, MyActivitiesMetafields } from '../types.d'
import ModalContainer from './ModalContainer'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { Picture } from '@components/ui'
import useActivities from '../hooks/useActivities'
import { CreditType, TaskSubType, TaskType } from '../const'
import { Task } from '../response'

interface ActivitiesModalProps extends ModalProps {
  data: MyActivitiesMetafields
}

function ActivitiesModal({ common, data, ...props }: ActivitiesModalProps) {
  const [page, setPage] = useState(1)

  const myActivitiesOptions = useMemo(() => {
    return {
      page: props.isOpen ? page : 0,
      pageSize: 1000,
    }
  }, [props.isOpen, page])

  const [activeTab, setActiveTab] = useState(CreditType.All)
  const tabs = useMemo(() => {
    return [
      {
        title: data.allTab,
        type: CreditType.All,
      },
      {
        title: data.earnedTab,
        type: CreditType.Earned,
      },
      {
        title: data.deductedTab,
        type: CreditType.Deducted,
      },
    ]
  }, [data])

  const creditColor = function (status: number, credit: number) {
    //冻结、新增，灰
    if (status == 2 && credit == 1) {
      return 'text-[#77777777]'
    }
    //非冻结、新增，绿
    if (status != 2 && credit == 1) {
      return 'text-[#52C41A]'
    }
    //只要减少了，红
    if (credit != 1) {
      return 'text-[#F84D4F]'
    }
    return 'text-[#F84D4F]'
  }

  const { activities = [], canNext } = useActivities(myActivitiesOptions)

  const list = useMemo(() => {
    const taskNameMap = {
      [TaskSubType.Activation]: data.activationTask,
      [TaskSubType.UpdateName]: data.updateNameTask,
      [TaskSubType.UpdatePhone]: data.updatePhoneTask,
      [TaskSubType.UpdateBirthday]: data.updateBirthdayTask,
      [TaskSubType.Subscription]: data.subscriptionTask,
      [TaskSubType.UpadteAppName]: data.updateAppNameTask,
      [TaskSubType.Refund]: data.refundTask,
      [TaskSubType.ProductUnApproved]: data.productUnApprovedTask,
    }

    function getTitle(item: Task) {
      if (
        item.task_type === TaskType.Redeem &&
        item.task_sub_type === TaskSubType.ProductUnApproved &&
        data.productUnApprovedTask
      ) {
        return item.task_name.replace('Refund', data.productUnApprovedTask)
      }

      return taskNameMap[item.task_sub_type] || item.task_name
    }

    //将时间戳转换为YY-MM-DD时间的通用函数
    function getLocalTime(n: number) {
      // 确保传入的是以毫秒为单位的标准时间戳
      let date = new Date(n)

      // 修复getMonth()返回值，使其从1开始
      let month = date.getMonth() + 1

      // 修复日期字符串格式，确保月份正确显示
      return (
        date.getFullYear() +
        '-' +
        (month < 10 ? '0' + month : month) +
        '-' +
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
      )
    }

    const all = activities.map((item) => ({
      title: getTitle(item),
      date: new Date(item.create_time * 1000),
      value: item.credit,
      type: item.credit_type,
      status: item.status,
      pending_time: getLocalTime(item.pending_time * 1000 + 86400 * 1000),
      credit_type: item.credit_type,
    }))
    return all
      .filter((item) => activeTab === CreditType.All || item.type === activeTab)
      .map((item) => {
        let { value, ...otherItem } = item
        if (item.type === CreditType.Deducted && value > 0) {
          value = -value
        }

        return {
          value,
          ...otherItem,
        }
      })
  }, [data, activeTab, activities])
  const handleScrollEnd = useCallback(() => {
    if (!canNext) {
      return
    }
    setPage(page + 1)
  }, [page, canNext])
  return (
    <ModalContainer
      title={data.title}
      className={classNames(
        'w-[640px] md:!min-h-0',
        list.length ? 'min-h-[578px]' : 'min-h-[406x]'
      )}
      useAnimation
      animationClassName="md:translate-y-[100vh]"
      {...props}
      onScrollEnd={handleScrollEnd}
    >
      <div className="grid gap-[32px]">
        <div className="flex justify-center">
          <div className="flex justify-center rounded-full bg-[#f2f0f1] text-[16px] font-medium leading-[1] text-[#333]">
            {tabs.map((tab) => (
              <div
                key={tab.type}
                className={classNames(
                  'cursor-pointer rounded-full px-[20px] py-[12px]',
                  activeTab === tab.type ? 'bg-[#333] text-[#fff]' : ''
                )}
                onClick={() => {
                  setActiveTab(tab.type)
                }}
              >
                {/* All  Earned Redeemed My Activites */}
                {tab.title}
              </div>
            ))}
          </div>
        </div>
        <div className="-mx-6 min-h-[200px] overflow-auto overscroll-contain min-l:-mx-8">
          <div className="grid gap-[24px] px-6 min-l:px-8">
            {/* 空处理 */}
            {!list.length && (
              <div className="flex h-full flex-col items-center justify-center">
                <Picture
                  // @ts-ignore
                  className="mb-[16px] h-[120px] w-[160px]"
                  source={common?.emptyListImageUrl}
                ></Picture>
                <div className="leading-1.4 text-[16px] font-medium">
                  {common?.emptyListLabel}
                </div>
              </div>
            )}
            {/* 积分获取的列表 */}
            {list.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between rounded-[8px] bg-[#f2f0f1] px-[16px] py-[20px] text-[16px]"
              >
                <div className="grid gap-[6px]">
                  <div className="font-semibold">{item.title}</div>

                  <div className="text-[14px] font-medium text-[#999]">
                    {dayjs(item.date).format('YYYY-MM-DD HH:mm').toString()}
                  </div>
                  {/* 列表项 */}
                  {item.status == 2 && item.credit_type != 2 ? (
                    <div className="text-[14px] font-medium text-[#999]">
                      {data.eufyEditComingSoonMessage?.replaceAll(
                        '${time}',
                        item.pending_time
                      )}
                    </div>
                  ) : null}
                </div>
                {/* /判断积分是否有效 */}
                <div
                  className={classNames(
                    'font-semibold',
                    creditColor(item.status, item.credit_type)
                  )}
                >
                  {item.value >= 0 && '+'}
                  {item.value}
                  {/* 分数 */}
                </div>
              </div>
            ))}
          </div>
        </div>
        {!!list.length && (
          <div className="text-[14px] font-medium text-[#999]">{data.tips}</div>
        )}
      </div>
    </ModalContainer>
  )
}

export default ActivitiesModal
